import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Table from "../../../components/Admin/Table";
import TransactionInfo from "../../../components/Admin/TransactionInfo";
import { GetAllPendingWithdrawal } from "../../../components/apis/adminApi";

const Withdrawal = () => {
  const [transactions, setTransactions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [expandedTrans, setExpandedTrans] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const getTransactions = async (pageNumber) => {
    setIsLoading(true);

    const transactionsRes = await GetAllPendingWithdrawal(pageNumber);

    if (!transactionsRes.status === 0 || !transactionsRes.data) {
      toast.error("Error Fetching Transactions");
      setIsLoading(false);
      setTransactions([]);
      return;
    }

    // Filter transactions with status "Pending"
    const pendingTransactions = transactionsRes.data.filter(
      (transaction) => transaction.status === "Pending"
    );

    const tempTransactions = pendingTransactions.map((transaction) => {
      const userData = [
        transaction.email,
        `₦${Number(transaction.amount).toLocaleString()}`,
        transaction.channel,
        <button onClick={() => setExpandedTrans(transaction.id)}>
          View Details
        </button>,
      ];
      return userData;
    });

    setIsLoading(false);
    setTransactions(tempTransactions);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    getTransactions(pageNumber);
  };

  useEffect(() => {
    getTransactions(currentPage);
  }, [currentPage]);

  return (
    <>
     <div>
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
          style={{
            backgroundColor: '#121212',
            color: '#ffffff',
            padding: '10px 15px',
            borderRadius: '5px',
            cursor: 'pointer',
            display: 'inline-block',
            marginBottom: "3rem"
          }}
        >
          Previous Page
        </button>
        <span> Page {currentPage} </span>
        <button onClick={() => handlePageChange(currentPage + 1)}
        style={{
          backgroundColor: '#121212',
          color: '#ffffff',
          padding: '10px 15px',
          borderRadius: '5px',
          cursor: 'pointer',
          display: 'inline-block',
          marginBottom: "3rem"
        }}>
          Next Page
        </button>
      </div>
      {expandedTrans !== 0 && (
        <TransactionInfo
          transactionId={expandedTrans}
          setTransactionId={setExpandedTrans}
          transactionType={"Withdrawal"}
        />
      )}
      <Table
        classes={"bordered hover"}
        title={"Pending Withdrawals"}
        tableHeaders={["S/N", "Email", "Amount", "Channel", "Action"]}
        tableData={transactions}
        isLoading={isLoading}
      />
     
    </>
  );
};

export default Withdrawal;
